/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Formik } from 'formik';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { customTheme } from '@lanexo2/ui-core';
import { TextField, Button, COLORS } from '@connectedlab/lanexo-liquid-design-react';
import WithTranslateFormErrors from './FormErrors';
import checkMark from '../Icons/checkMark.svg';
import wrongMark from '../Icons/wrongMark.svg';
import '../css/ResetPasswordForm.css';
import { validatePasswordAndConfPassword } from '../utils/validatePassword';

interface IProps {
    handleFormSubmit: () => void;
    setValues: (item) => void;
    passwordPolicy: any;
}

const customButtonLabelStyle: any = css`
    div {
        color: ${customTheme.colors.secondary.base} !important;
    }
`;

const customButtonIconStyle: any = css`
    float: right;
    margin-top: -40px;
    svg {
        fill: ${customTheme.colors.secondary.base} !important;
    }
`;
const customButtonDisabledLabelStyle: any = css`
    background-color: ${COLORS.VIBRANT_YELLOW_LIGHTEST} !important;
`;

export const ResetPasswordForm: any = ({ handleFormSubmit, setValues, passwordPolicy }: IProps): any => {
    const { t }: UseTranslationResponse<'translation'> = useTranslation();
    const [values, setValue] = useState<any>({
        newPassword: '',
        passwordRepeat: '',
    });
    const [errors, setErrors] = useState({
        password: '',
        passwordRepeat: '',
        valid: false,
    });

    const setStateErrorsForValid = () =>
        setErrors((prevState) => ({
            ...prevState,
            valid: true,
            passwordRepeat: '',
        }));

    const setStateErrorsForInValid = () =>
        setErrors((prevState) => ({
            ...prevState,
            valid: false,
            passwordRepeat: 'Passwords do not match',
        }));

    useEffect(() => {
        setValues(values);
    }, [errors.valid]);

    useEffect(() => {
        const value = values.newPassword ? values.newPassword : values.passwordRepeat && values.passwordRepeat
        values.newPassword && values.passwordRepeat && validatePasswordAndConfPassword(
            value,
            values,
            passwordPolicy,
            setStateErrorsForValid,
            setStateErrorsForInValid,
        )
    }, [values]);

    return (
        <Formik initialValues={{ password: '', passwordRepeat: '' }} onSubmit={handleFormSubmit}>
            {({ handleBlur, handleSubmit }) => (
                <WithTranslateFormErrors errors={errors}>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            grey={true}
                            label={t('common.password')}
                            type="password"
                            name="password"
                            data-testid="reset-password"
                            value={values.password}
                            onChange={(input) => {
                                setValue((prevState) => ({
                                    ...prevState,
                                    newPassword: input,
                                }));
                            }}
                            error={errors.password && t(`${errors.password}`)}
                            onBlur={handleBlur}
                            style={{ display: 'block' }}
                        />
                        <TextField
                            grey={true}
                            label={t('passwordReset.passwordRepeatLabel')}
                            type="password"
                            data-testid="reset-repeat-password"
                            name="passwordRepeat"
                            value={values.passwordRepeat}
                            onChange={(input) => {
                                setValue((prevState) => ({
                                    ...prevState,
                                    passwordRepeat: input,
                                }));
                            }}
                            error={errors.passwordRepeat && t(`${errors.passwordRepeat}`)}
                            onBlur={handleBlur}
                            style={{ display: 'block' }}
                        />
                        <p className="resetPasswordHeader">{t('common.resetPasswordHeader')}</p>
                        {passwordPolicy?.mustHaveCharacters && (
                            <p className="passwordPossibility">
                                <img
                                    className="icontMark"
                                    src={values.newPassword.match(/[A-Za-z]/) ? checkMark : wrongMark}
                                    alt="oneChar"
                                />
                                {t('common.oneChar')}
                            </p>
                        )}
                        {passwordPolicy?.mustHaveNumbers && (
                            <p className="passwordPossibility">
                                <img
                                    className="icontMark"
                                    src={values.newPassword.match(/[0-9]/) ? checkMark : wrongMark}
                                    alt="oneNum"
                                />
                                {t('common.oneNum')}
                            </p>
                        )}
                        {passwordPolicy?.mustHaveSpecialCharacters && (
                            <p className="passwordPossibility">
                                <img
                                    className="icontMark"
                                    src={
                                        values.newPassword.match(/[^A-Za-z0-9]/)
                                            ? checkMark
                                            : wrongMark
                                    }
                                    alt="oneSpecialChar"
                                />
                                {t('common.oneSpecialChar')}
                            </p>
                        )}
                        <p className="passwordPossibility">
                            <img
                                className="icontMark"
                                src={values.newPassword.length >= passwordPolicy?.minimumLength ? checkMark : wrongMark}
                                alt="minEightChar"
                            />
                            {t(`Minimum ${passwordPolicy?.minimumLength || 6} characters`)}
                        </p>
                        <Button
                            type="submit"
                            data-testid="reset-submit"
                            appearance="primary"
                            size="big"
                            disabled={
                                !(errors.valid && (values.newPassword.length >= passwordPolicy?.minimumLength))
                            }
                            css={[
                                customButtonIconStyle,
                                ...(errors.valid && values.newPassword.length >= passwordPolicy?.minimumLength
                                    ? [customButtonLabelStyle]
                                    : [customButtonDisabledLabelStyle]),
                            ]}
                        >
                            {t('common.submit')}
                        </Button>
                    </form>
                </WithTranslateFormErrors>
            )}
        </Formik>
    );
};
