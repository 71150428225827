export const de = {
    translation: {
        common: {
            email: 'E-Mail',
            password: 'Passwort',
            welcome: 'Willkommen!',
            Login: 'Einloggen',
            language: 'Sprache / Language',
            lang: {
                de: 'Deutsch',
                en: 'English',
            },
            submit: 'Absenden',
            forgotPassword: 'Passwort vergessen?',
            rememberMe: 'Eingeloggt bleiben',
            forgotPasswordContent:
                'Bitte tragen Sie Ihre E-Mail Addresse ein. Sie erhalten danach einen Link zum Zurücksetzen des Passwortes per E-Mail.',
            UseTheApp: 'Login über die App',
            label1: 'Öffnen Sie die App und gehen Sie zu Ihrem Profil.',
            label2: 'Login bestätigen',
            Back: 'Zurück',
            ConfirmationPending: 'Auf Bestätigung warten',
            linkImprint: 'Impressum',
            linkPrivacyPolicy: 'Datenschutz',
            linkTermsOfUse: 'AGB',
            relativeTimeInFuture: 'einige Zeit',
            resetPasswordHeader: 'Das Passwort muss folgendes enthalten:',
            oneChar: 'Fügen Sie mindestens einen Buchstaben ein',
            oneNum: 'Fügen Sie mindestens eine Ziffer ein',
            oneSpecialChar: 'Fügen Sie mindestens ein Sonderzeichen ein',
            minEightChar: 'Mindestens X characters',
            PasswordResetToken:
                'Das Kennwort wurde nicht festgelegt oder die von Ihnen eingegeben Kennwörter stimmen nicht überein.',
            PasswordResetLink: 'Der Link zum Zurücksetzen des Passworts wird an Ihre E-Mail geschickt.',
            credentials: 'Referenzen',
            hybrid: 'Hybrid',
            loaderHeader: 'Einen Moment bitte, Ihre Anmeldeinformationen werden validiert…',
        },
        passwordReset: {
            headline: 'Passwort zurücksetzen',
            passwordRepeatLabel: 'Passwort wiederholen',
            passwordsDoNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein',
            submitButton: 'Passwort festlegen',
            wasSubmitted:
                'Ihre Anfrage zum Zurücksetzen des Passworts wurde übermittelt. Bitte kehren Sie nun zur <0>Login Seite</0> zurück und loggen sich mit Ihrem neu vergebenen Passwort ein.',
        },
        passwordSet: {
            headline: 'Passwort festlegen',
            passwordRepeatLabel: 'Passwort bestätigen',
            passwordsDoNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein',
            submitButton: 'Passwort festlegen',
            wasSubmitted:
                'Ihr Passwort wurde gespeichert. Bitte kehren Sie zur <0>Anmeldeseite</0> zurück und melden Sie sich mit Ihrem neuen Passwort an.',
        },
        passwordUpdate: {
            headline: 'Passwort Aktualisieren',
            oldPassword: 'Altes Passwort',
            newPassword: 'Neues Passwort',
            passwordRepeatLabel: 'Bestätige neues Passwort',
            passwordsDoNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein',
            submitButton: 'Passwort Aktualisieren',
            passwordPlocy: 'Die Passwortrichtlinie wurde geändert, bitte richten Sie ein neues Passwort ein',
            samePassword:
                'Das neue Passwort darf nicht identisch mit dem alten Passwort sein. Bitte versuchen Sie es erneut.',
            passwordNotMatch: 'Passwörter stimmen nicht überein.',
            wasSubmitted:
                'Ihr Passwort wurde aktualisiert. Bitte kehren Sie zur <0>Login-Seite</0> zurück und loggen Sie sich mit dem neuen Passwort ein.',
        },
        errors: {
            ACCOUNT_LOGIN_BLOCKED:
                'Das Passwort wurde zu häufig falsch eingegeben. Bitte warten Sie {{time}} Minuten bis zum nächsten Versuch.',
            ACCOUNT_LOGIN_BLOCKED_MINUTES:
                'Das Passwort wurde zu häufig falsch eingegeben. Bitte warten Sie eine Minute bis zum nächsten Versuch.',
            ACCOUNT_LOGIN_BLOCKED_SECONDS:
                'Das Passwort wurde zu häufig falsch eingegeben. Bitte warten Sie ein paar Sekunden bis zum nächsten Versuch.',
            ACCOUNT_LOGIN_BLOCKED_SOME_TIME:
                'Das Passwort wurde zu häufig falsch eingegeben. Bitte warten Sie {{time}} bis zum nächsten Versuch.',
            INVALID_PASSWORD: 'Sie haben ein falsches Passwort verwendet.',
            INVALID_ACCESS_CARD: 'Sie haben ein falsche Zugangskarte verwendet.',
            INVALID: 'Sie haben eine falsche E-Mail-Adresse oder ein falsches Passwort eingegeben.',
            SOMETHING_WRONG: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut.',
            APPROVAL_REQUIRED: 'Ihre Registrierung wartet auf Genehmigung',
            TOKEN_EXPIRED: 'Oops..Der Link ist abgelaufen!',
            INVALID_TOKEN: 'Oops.. Der Link ist ungültig!',
            PASSWORD_POLICY_NOT_FOLLOWED: 'Das Passwort entspricht nicht den Passwortrichtlinien',
            LENGTH_DOES_NOT_MATCH: 'Die Länge des Passwortes ist nicht ausreichend',
            INVALID_PASSWORD_POLICY: 'Ungültig Passwortrichtlinien',
            EXISTING_PASSWORD: 'Das neue Passwort muss sich vom alten Passwort unterscheiden',
            INVALID_PARTIAL_USER_LOGIN:
                'Die Registrierung Ihres Benutzers ist noch nicht abgeschlossen. Bitte schließen Sie zuerst Ihre Benutzerregistrierung ab.',
            UNKNOWN_DEVICE:
                'Das Gerät wurde noch nicht aktiviert. Bitte kontaktieren Sie den zuständigen Gerätemanager.',
            USER_INACTIVE: 'Ihr Bentuzerkonto wurde deaktiviert. Sie sind nicht berechtigt sich einzuloggen.',
            MISSING_APPROVAL: 'Ihre Registrierung muss durch einen LANEXO Administrator genehmigt werden',
            notType: 'Bitte geben Sie einen Wert vom richtigen Typ ein.',
            required: 'Bitte geben Sie einen Wert ein.',
            email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
            // eslint-disable-next-line
            max: 'Bitte nicht mehr als 72 Zeichen verwenden',
            // eslint-disable-next-line
            min: 'Bitte mindestens 6 Zeichen verwenden',
            CUSTOMER_INACTIVE: 'Ihr Konto ist derzeit nicht aktiv, bitte kontaktieren Sie Ihren LANEXO Administrator.',
            CUSTOMER_START_DATE:
                'Sie können sich nicht vor dem Startdatum Ihres Kontos anmelden, bitte kontaktieren Sie Ihren LANEXO Administrator.',
            CUSTOMER_END_DATE:
                'Sie können sich nicht vor dem Enddatum Ihres Kontos anmelden, bitte kontaktieren Sie Ihren LANEXO Administrator.',
            UserLicenceChanges:
                'Aufgrund von Wartungsarbeiten ist der Login derzeit nur für Administratoren möglich. Kontaktieren sie Ihren Administrator.',
        },
        key: {
            columnMode: 'Spaltenansicht',
            headline: 'Schlüssel eingeben',
            label: 'Zugangskartenschlüssel eingeben',
            textMode: 'Textansicht',
        },
    },
};
