/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Formik } from 'formik';
import { TextField, Button, COLORS } from '@connectedlab/lanexo-liquid-design-react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { customTheme } from '@lanexo2/ui-core';
import WithTranslateFormErrors from './FormErrors';
import '../css/Form.css';
import checkMark from '../Icons/checkMark.svg';
import wrongMark from '../Icons/wrongMark.svg';
import { useEffect, useState } from 'react';
import { validatePasswordAndConfPassword } from '../utils/validatePassword';

const customButtonLabelStyle: any = css`
    div {
        color: ${customTheme.colors.secondary.base} !important;
    }
`;

const customButtonIconStyle: any = css`
    float: right;
    margin-top: -40px;
    svg {
        fill: ${customTheme.colors.secondary.base} !important;
    }
`;
const customButtonDisabledLabelStyle: any = css`
    background-color: ${COLORS.VIBRANT_YELLOW_LIGHTEST} !important;
`;

interface IProps {
    handleFormSubmit: () => void;
    setValues: (item) => void;
    passwordPolicy: any;
    emailAddress: any;
}
const LoginForm: any = ({ handleFormSubmit, passwordPolicy, setValues, emailAddress }: IProps): any => {
    const { t }: UseTranslationResponse<'translation'> = useTranslation();
    const [errors, setErrors] = useState({
        password: '',
        passwordRepeat: '',
        valid: false,
    });
    const [values, setValue] = useState<any>({
        email: emailAddress ? emailAddress : '',
        oldPassword: '',
        newPassword: '',
        passwordRepeat: '',
    });

    const setStateErrorsForValid = () =>
        setErrors((prevState) => ({
            ...prevState,
            valid: true,
            passwordRepeat: '',
        }));

    useEffect(() => {
        setValues(values);
    }, [values, setValues]);

    const setStateErrorsForInValid = () =>
        setErrors((prevState) => ({
            ...prevState,
            valid: false,
            passwordRepeat: t('passwordUpdate.passwordNotMatch'),
        }));

    useEffect(() => {
        if (values?.newPassword && values?.oldPassword && values?.newPassword === values?.oldPassword) {
            setErrors((prevState) => ({
                ...prevState,
                valid: false,
                password: t('passwordUpdate.samePassword'),
                passwordRepeat: '',
            }));
        } else if (values?.newPassword && values?.passwordRepeat && values?.newPassword !== values?.passwordRepeat) {
            setErrors((prevState) => ({
                ...prevState,
                valid: false,
                password: '',
                passwordRepeat: t('passwordUpdate.passwordNotMatch'),
            }));
        } else if (values?.newPassword && values?.oldPassword && values?.passwordRepeat && values?.newPassword !== values?.oldPassword && values?.newPassword === values?.passwordRepeat) {
            setErrors((prevState) => ({
                ...prevState,
                valid: true,
                password: '',
                passwordRepeat: '',
            }));
        } else {
            setErrors((prevState) => ({
                ...prevState,
                valid: false,
                password: '',
                passwordRepeat: '',
            }));
        }
    }, [values])

    useEffect(() => {
        setValues(values);
    }, [errors.valid]);

    useEffect(() => {
        const value = values.newPassword ? values.newPassword : values.passwordRepeat && values.passwordRepeat
        values.newPassword && values.passwordRepeat && validatePasswordAndConfPassword(
            value,
            values,
            passwordPolicy,
            setStateErrorsForValid,
            setStateErrorsForInValid,
        )
    }, [values]);

    return (
        <>
            <Formik
                initialValues={{ email: '', oldPassword: '', newPassword: '', passwordRepeat: '' }}
                onSubmit={handleFormSubmit}
            >
                {({ handleBlur, handleSubmit }) => (
                    <WithTranslateFormErrors errors={errors}>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                grey={true}
                                label={t('common.email')}
                                data-testid="email"
                                type="email"
                                name="email"
                                value={values.email}
                                onBlur={handleBlur}
                                style={{ display: 'block' }}
                            />

                            <TextField
                                grey={true}
                                label={t('passwordUpdate.oldPassword')}
                                type="password"
                                name="oldPassword"
                                data-testid="old-password"
                                value={values.oldPassword}
                                onChange={(input) => {
                                    setValue((prevState) => ({
                                        ...prevState,
                                        oldPassword: input,
                                    }))
                                }}
                                onBlur={handleBlur}
                                style={{ display: 'block' }}
                            />
                            <TextField
                                grey={true}
                                label={t('passwordUpdate.newPassword')}
                                type="password"
                                name="newPassword"
                                data-testid="new-password"
                                value={values.newPassword}
                                onChange={(input) => {
                                    setValue((prevState) => ({
                                        ...prevState,
                                        newPassword: input,
                                    }));
                                }}
                                error={errors.password && t(`${errors.password}`)}
                                onBlur={handleBlur}
                                style={{ display: 'block' }}
                            />
                            <TextField
                                grey={true}
                                label={t('passwordUpdate.passwordRepeatLabel')}
                                type="password"
                                data-testid="set-repeat-password"
                                name="passwordRepeat"
                                value={values.passwordRepeat}
                                onChange={(input) => {
                                    setValue((prevState) => ({
                                        ...prevState,
                                        passwordRepeat: input,
                                    }));
                                }}
                                error={errors.passwordRepeat && t(`${errors.passwordRepeat}`)}
                                onBlur={handleBlur}
                                style={{ display: 'block' }}
                            />
                            {passwordPolicy ? (
                                <p className="resetPasswordHeader">{t('common.resetPasswordHeader')}</p>
                            ) : (
                                <br></br>
                            )}
                            {passwordPolicy?.mustHaveCharacters && (
                                <p className="passwordPossibility">
                                    <img
                                        className="icontMark"
                                        src={values.newPassword.match(/[A-Za-z]/) ? checkMark : wrongMark}
                                        alt="oneChar"
                                    />
                                    {t('common.oneChar')}
                                </p>
                            )}
                            {passwordPolicy?.mustHaveNumbers && (
                                <p className="passwordPossibility">
                                    <img
                                        className="icontMark"
                                        src={values.newPassword.match(/[0-9]/) ? checkMark : wrongMark}
                                        alt="oneNum"
                                    />
                                    {t('common.oneNum')}
                                </p>
                            )}
                            {passwordPolicy?.mustHaveSpecialCharacters && (
                                <p className="passwordPossibility">
                                    <img
                                        className="icontMark"
                                        src={
                                            values.newPassword.match(/[^A-Za-z0-9]/)
                                                ? checkMark
                                                : wrongMark
                                        }
                                        alt="oneSpecialChar"
                                    />
                                    {t('common.oneSpecialChar')}
                                </p>
                            )}
                            {passwordPolicy?.minimumLength && (
                                <p className="passwordPossibility">
                                    <img
                                        className="icontMark"
                                        src={
                                            values.newPassword.length >= passwordPolicy?.minimumLength
                                                ? checkMark
                                                : wrongMark
                                        }
                                        alt="minEightChar"
                                    />
                                    {t(`Minimum ${passwordPolicy?.minimumLength || 6} characters`)}
                                </p>
                            )}
                            <Button
                                type="submit"
                                data-testid="set-submit"
                                appearance="primary"
                                size="big"
                                disabled={
                                    !(errors.valid && (values?.newPassword.length >= passwordPolicy?.minimumLength) && values?.oldPassword && (values?.newPassword !== values?.oldPassword))
                                }
                                css={[
                                    customButtonIconStyle,
                                    ...(errors.valid && values?.newPassword.length >= passwordPolicy?.minimumLength && values?.oldPassword && values?.newPassword !== values?.oldPassword
                                        ? [customButtonLabelStyle]
                                        : [customButtonDisabledLabelStyle]),
                                ]}
                            >
                                {t('common.submit')}
                            </Button>
                        </form>
                    </WithTranslateFormErrors>
                )}
            </Formik>
        </>
    );
};

export default LoginForm;
