import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

window.localStorage.clear();

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<div>..Loading</div>}>
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('login-module'),
);
