import { getCookie } from '@lanexo2/ui-core';
import { setCookie } from "../cookie";

export const getDeviceId: any = () => {
    let deviceId: any = getCookie("deviceId");
    if (!deviceId) {
      deviceId = generateUUID();
      setCookie('deviceId', deviceId as string,"Fri, 31 Dec 9999 23:59:59 GMT");
    }
    return deviceId;
  };

  const generateUUID: any = () => {
    let
      d = new Date().getTime(),
      d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
  };