/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Col, Row } from 'antd';
import React from 'react';
import { Theme, COLORS } from '@connectedlab/lanexo-liquid-design-react';
import { customTheme, defaultBottomMargin, defaultMargin, minScreenLg, minScreenSm } from '@lanexo2/ui-core';
import logo from '../images/logo.svg';
import { DefaultFooter } from '../components/Footer';

interface LayoutProps {
    children: any;
}

export const Layout: React.FunctionComponent<LayoutProps> = (props: LayoutProps): React.ReactElement => {
    return (
        <Theme customTheme={customTheme}>
            <div
                css={css`
                    background-color: ${COLORS.VIBRANT_YELLOW};
                    display: flex;
                    flex-direction: column;

                    .LD__Modal {
                        z-index: 1000;
                    }

                    .LD__OptionsGroup {
                        z-index: 10;
                    }

                    .ReactModal__Overlay {
                        background-color: rgba(27, 27, 37, 0.7);
                    }
                    .ReactModal__Content {
                        -ms-overflow-style: auto;
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        min-height: 100vh;
                    `}
                >
                    <div
                        css={css`
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            overflow: hidden;
                            padding: 10px;

                            @media (min-width: 450px) {
                                padding-top: 25px;
                                padding-left: 25px;
                                padding-right: 25px;
                            }
                            @media (min-width: 900px) {
                                padding-left: 50px;
                                padding-right: 50px;
                            }
                        `}
                    >
                        <div
                            css={css`
                                flex: 1 0 auto;
                                width: 100%;
                                margin: 0 auto;
                            `}
                            id={'contentWrapper'}
                        >
                            <div
                                css={css`
                                    ${minScreenSm(`padding: 72px;`)}
                                `}
                            >
                                <Row>
                                    <Col
                                        xs={24}
                                        lg={10}
                                        css={css`
                                            text-align: center;
                                            ${minScreenLg(`text-align: left;`)}
                                        `}
                                    >
                                        <img
                                            css={css`
                                                width: 10rem;
                                                margin-top: ${defaultMargin};
                                                ${minScreenSm(`width: 11rem; margin-top: 0;`)}
                                                ${defaultBottomMargin}
                                            `}
                                            src={logo}
                                            alt="Logo"
                                        />
                                    </Col>
                                    <Col xs={24} lg={14}>
                                        <div
                                            css={css`
                                                background-color: ${COLORS.WHITE};
                                                border-radius: 6px;
                                                padding: 25px;
                                                ${defaultBottomMargin}
                                            `}
                                        >
                                            {props.children}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <DefaultFooter />
                    </div>
                </div>
            </div>
        </Theme>
    );
};
