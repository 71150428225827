/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Formik } from 'formik';
import { Headline, Notifications, TextField, Button, COLORS } from '@connectedlab/lanexo-liquid-design-react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { customTheme, defaultSmBottomMargin } from '@lanexo2/ui-core';
import * as yup from 'yup';
import WithTranslateFormErrors from './FormErrors';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import '../css/ResetPasswordForm.css';
import { useEffect, useState } from 'react';

const customButtonLabelStyle: any = css`
    div {
        color: ${customTheme.colors.secondary.base} !important;
    }
`;

const customButtonIconStyle: any = css`
    float: right;
    margin-top: -15px;
    svg {
        fill: ${customTheme.colors.secondary.base} !important;
    }
`;
const customButtonDisabledLabelStyle: any = css`
    background-color: ${COLORS.VIBRANT_YELLOW_LIGHTEST} !important;
`;

export interface IForgotPasswordData {
    email: string;
}
const ForgotPasswordForm: any = (): any => {
    const { t }: UseTranslationResponse<'translation'> = useTranslation();
    const [historyPush, setHistoryPush] = useState(false);
    const validationSchema: any = yup.object().shape({
        email: yup.string().email('errors.email').required('errors.required'),
    });
    let notificationsRef: any;
    const history = useHistory();

    useEffect(() => {
        historyPush && history.push('/');
    }, [historyPush]);

    const handleFormSubmit: any = (
        values: IForgotPasswordData,
        { setSubmitting }: { setSubmitting: (value: boolean) => void },
    ) => {
        const forgotPasswordValues: any = {
            email: values.email,
        };
        fetch('/api/authorization/request-password-reset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(forgotPasswordValues),
        })
            .then(async (response) => {
                if (!response.ok) {
                    throw await response.json();
                }
                return response;
            })
            .then((res) => {
                if (res.ok) {
                    notificationsRef.addNotification({
                        text: t('common.PasswordResetLink'),
                        isInfo: true,
                    });
                }
                setTimeout(() => {
                    setHistoryPush(true);
                    setSubmitting(false);
                }, 2000);
            })
            .catch((error) => {
                if (error.message === 'User not found') {
                    notificationsRef.addNotification({
                        text: error.message,
                        isInfo: true,
                    });
                } else {
                    notificationsRef.addNotification({
                        text: t('errors.SOMETHING_WRONG'),
                        isInfo: true,
                    });
                }
                setHistoryPush(false);
                setSubmitting(false);
            });
    };

    return (
        <>
            <Formik
                initialValues={{ email: '', skipSession: false }}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            >
                {({
                    values,
                    handleBlur,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    errors,
                    touched,
                    isValid,
                    dirty,
                }) => (
                    <WithTranslateFormErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                        <form onSubmit={handleSubmit}>
                            <Headline type="H2" css={defaultSmBottomMargin}>
                                {t('common.forgotPassword')}
                            </Headline>
                            <p className="forgotPasswordContent">{t('common.forgotPasswordContent')}</p>
                            <TextField
                                grey={true}
                                label={t('common.email')}
                                data-testid="loginEmail"
                                name="email"
                                value={values.email}
                                onChange={(input) => setFieldValue('email', input)}
                                error={touched.email && errors.email && t(`${errors.email}`)}
                                onBlur={handleBlur}
                                style={{ display: 'block' }}
                            />

                            <div
                                css={css`
                                    text-align: right;
                                    padding: 30px;
                                `}
                            >
                                <Button
                                    type="submit"
                                    data-testid="loginSubmit"
                                    appearance="primary"
                                    size="big"
                                    disabled={isSubmitting || !(isValid && dirty)}
                                    css={[
                                        customButtonIconStyle,
                                        ...(!(isSubmitting || !(isValid && dirty))
                                            ? [customButtonLabelStyle]
                                            : [customButtonDisabledLabelStyle]),
                                    ]}
                                >
                                    <div className="sAmpM"> {i18n.t('common.submit')}</div>
                                </Button>
                            </div>
                        </form>
                    </WithTranslateFormErrors>
                )}
            </Formik>
            <Notifications
                ref={(v) => {
                    notificationsRef = v;
                }}
            />
        </>
    );
};

export default ForgotPasswordForm;
