import React, { useEffect, useState } from 'react';
import { Headline, Notifications } from '@connectedlab/lanexo-liquid-design-react';
import { Link } from 'react-router-dom';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { Trans } from 'react-i18next';
import UpdatePasswordForm from '../components/UpdatePasswordForm';
import { useLocation } from 'react-router-dom';
import '../languages/i18n';

export const UpdatePassword: any = () => {
    const [wasSubmitted, setWasSubmitted]: [any, (any) => void] = useState(false);
    const [values, setValue] = useState({
        email: '',
        oldPassword: '',
        newPassword: '',
        passwordRepeat: '',
    });
    const [passwordPolicy, setPasswordPolicy] = useState<any>();
    const { t }: UseTranslationResponse<'translation'> = useTranslation();
    let notificationsRef: any;

    const location = useLocation();

    useEffect(() => {
        setValue((prevState) => ({
            ...prevState,
            email: location?.emailAddress,
        }));
        fetchPasswordPolicy(location?.emailAddress);
    }, [location?.emailAddress]);

    const fetchPasswordPolicy = (emailId) => {
        const url = `/api/authorization/fetch-auth-policy?email=${emailId}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                if (!response.ok) {
                    const message: any = await response.json().then((data) => data?.message || 'Error');
                    throw message;
                }
                return response.json();
            })
            .then((response) => {
                setPasswordPolicy(response);
            })
            .catch(() => {
                setPasswordPolicy({
                    passwordId: 4,
                    authOption: "MinimumValues",
                    minimumLength: 6,
                    mustHaveCharacters: false,
                    mustHaveNumbers: false,
                    mustHaveSpecialCharacters: false
                })
            });
    };

    const handleFormSubmit: any = () => {
        const loginvalues: any = {
            emailAddress: values.email,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        };

        fetch('/api/authorization/update-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginvalues),
        })
            .then(async function (response) {
                if (!response.ok) {
                    return response.json().then((json) => {
                        throw json;
                    });
                }
                return response;
            })
            .then(() => {
                setWasSubmitted(true);
            })
            .catch((error) => {
                if (error.errorCode === 'INVALID_PASSWORD_POLICY') {
                    notificationsRef.addNotification({
                        text: t('errors.INVALID_PASSWORD_POLICY'),
                        isInfo: true,
                    });
                } else if (error.errorCode === 'PASSWORD_POLICY_NOT_FOLLOWED') {
                    notificationsRef.addNotification({
                        text: t('errors.PASSWORD_POLICY_NOT_FOLLOWED'),
                        isInfo: true,
                    });
                } else if (error.errorCode === 'LENGTH_DOES_NOT_MATCH') {
                    notificationsRef.addNotification({
                        text: t('errors.LENGTH_DOES_NOT_MATCH'),
                        isInfo: true,
                    });
                } else if (error.errorCode === 'EXISTING_PASSWORD') {
                    notificationsRef.addNotification({
                        text: t('errors.EXISTING_PASSWORD'),
                        isInfo: true,
                    });
                } else {
                    notificationsRef.addNotification({
                        text: t('errors.SOMETHING_WRONG'),
                        isInfo: true,
                    });
                }
            });
    };

    return (
        <div className="wasSubmitted">
            <Headline type="H3" style={{ marginBottom: '10px' }}>
                {t('passwordUpdate.headline')}
            </Headline>
            {!wasSubmitted && <p className="forgotPasswordContent">{t('passwordUpdate.passwordPlocy')}</p>}
            {wasSubmitted ? (
                <Trans i18nKey={'passwordUpdate.wasSubmitted'} components={[<Link key={0} to={'/'} />]} />
            ) : (
                <UpdatePasswordForm
                    handleFormSubmit={handleFormSubmit}
                    passwordPolicy={passwordPolicy}
                    setValues={setValue}
                    emailAddress={location.emailAddress}
                />
            )}
            <Notifications
                ref={(v) => {
                    notificationsRef = v;
                }}
            />
        </div>
    );
};
