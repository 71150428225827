export const en = {
    translation: {
        common: {
            email: 'Email',
            password: 'Password',
            welcome: 'Welcome!',
            Login: 'Login',
            language: 'Language',
            lang: {
                de: 'Deutsch',
                en: 'English',
            },
            submit: 'Submit',
            forgotPassword: 'Forgot Password?',
            rememberMe: 'Remember me',
            forgotPasswordContent:
                'Please enter you email address below. Password reset link will be sent to your email.',
            UseTheApp: 'Use the App to Login',
            label1: 'Open the app and go to your profile',
            label2: 'Confirm Login',
            Back: 'Back',
            ConfirmationPending: 'Confirmation Pending',
            linkImprint: 'Imprint',
            linkPrivacyPolicy: 'Privacy Policy',
            linkTermsOfUse: 'Terms of Use',
            relativeTimeInFuture: 'For Some Time',
            resetPasswordHeader: 'Password must include:',
            oneChar: 'Include at least one character',
            oneNum: 'Include at least one number',
            oneSpecialChar: 'Include at least one special character',
            minEightChar: 'Minimum 8 characters',
            PasswordResetToken:
                'The password has not been set or the passwords that you have entered are not matching.',
            PasswordResetLink: 'Password reset link will be sent to your email.',
            credentials: 'Credentials',
            hybrid: 'Hybrid',
            loaderHeader: 'Please wait. Validating your credentials...',
        },
        passwordReset: {
            headline: 'Reset Password',
            passwordRepeatLabel: 'Confirm Password',
            passwordsDoNotMatch: 'The entered passwords do not match',
            submitButton: 'Set Password',
            wasSubmitted:
                'Your password reset request was sent. Please return to the <0>Login Page</0> and login with your newly set password.',
        },
        passwordSet: {
            headline: 'Set Password',
            passwordRepeatLabel: 'Confirm Password',
            passwordsDoNotMatch: 'The entered passwords do not match',
            submitButton: 'Set Password',
            wasSubmitted:
                'Your password has been saved. Please return to the <0>Login Page</0> and log in with your new password.',
        },
        passwordUpdate: {
            headline: 'Update Password',
            oldPassword: 'Old Password',
            newPassword: 'New Password',
            passwordRepeatLabel: 'Confirm New Password',
            passwordsDoNotMatch: 'The entered passwords do not match',
            submitButton: 'Update Password',
            passwordPlocy: 'Password policy has been changed, please set up a new password',
            samePassword: "The new password can't be same as old password. Please try again",
            passwordNotMatch: 'Passwords do not match',
            wasSubmitted:
                'Your password has been updated. Please return to the <0>Login Page</0> and log in with your new password.',
        },
        errors: {
            ACCOUNT_LOGIN_BLOCKED:
                'A wrong password was provided too many times. Please wait {{time}} minutes until the next try.',
            ACCOUNT_LOGIN_BLOCKED_MINUTES:
                'A wrong password was provided too many times. Please wait a minute until the next try.',
            ACCOUNT_LOGIN_BLOCKED_SECONDS:
                'A wrong password was provided too many times. Please wait a few seconds until the next try.',
            ACCOUNT_LOGIN_BLOCKED_SOME_TIME:
                'A wrong password was provided too many times. Please wait {{time}} until the next try.',
            INVALID_PASSWORD: 'You entered an incorrect password.',
            INVALID_ACCESS_CARD: 'You entered an incorrect access card.',
            INVALID: 'You entered an incorrect email address or password.',
            SOMETHING_WRONG: 'Something went wrong, please try again.',
            TOKEN_EXPIRED: 'Oops.. the link is invalid!',
            INVALID_TOKEN: 'Oops..the link is invalid!',
            PASSWORD_POLICY_NOT_FOLLOWED: 'Password is not according the password policy',
            LENGTH_DOES_NOT_MATCH: 'Password length does not match',
            INVALID_PASSWORD_POLICY: 'Invalid Password Policy',
            EXISTING_PASSWORD: 'Old and new password cannot be same',
            INVALID_PARTIAL_USER_LOGIN:
                'The registration of your user has not been completed yet. Please finish your user registration first',
            APPROVAL_REQUIRED: 'Your registration is pending for approval',
            UNKNOWN_DEVICE: 'The device is not activated yet. Please contact the responsible device manager.',
            USER_INACTIVE: 'Your user account was deactivated. You are not allowed to login.',
            MISSING_APPROVAL: 'Your registration needs to be approved by a LANEXO Administrator',
            notType: 'Please provide a value of the correct type.',
            required: 'Please provide a value.',
            email: 'Please enter a valid email address.',
            max: 'Enter no more than 72 characters',
            min: 'Enter at least 6 characters',
            CUSTOMER_INACTIVE: 'Your account is currently not active, please contact your LANEXO Administrator.',
            CUSTOMER_START_DATE:
                'You cannot login before your account start date, please contact your LANEXO Administrator.',
            CUSTOMER_END_DATE:
                'You cannot login after your account end date, please contact your LANEXO Administrator.',
            UserLicenceChanges:
                'Login currently only possible for Administrators due to pending maintenance. Contact your administrator.',
        },
        key: {
            columnMode: 'Column View',
            headline: 'Enter Access Card Key',
            label: 'Access Card Key',
            textMode: 'Text View',
        },
    },
};
