/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { COLORS } from '@connectedlab/lanexo-liquid-design-react';
import { Icons } from './index';

interface IProps {
    name: keyof typeof Icons;
    color: string;
}

export const CustomIcon: React.FunctionComponent<IProps> = ({ name, color = COLORS.RICH_BLACK }: IProps) => {
    const IconTag: any = Icons[name];
    return (
        <span
            data-testid="customIcon"
            css={css`
                display: inline-flex;
                align-self: center;
            `}
        >
            <IconTag
                fill={color}
                css={css`
                    height: 1em;
                    width: 1em;
                    top: 0.2em;
                    position: relative;
                `}
            />
        </span>
    );
};
