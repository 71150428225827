import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { de } from './de';
import { en } from './en';

export const i18n = i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        interpolation: {
            // with react there is no escaping required
            escapeValue: false,
        },
        resources: {
            de,
            en,
        },
        detection: {
            lookupLocalStorage: 'i18nextLng',
            lookupCookie: 'i18nextLng',
            order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
        },
        whitelist: ['de', 'en'],
    });
