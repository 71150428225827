/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Col, Row } from 'antd';
import { Headline, Bubble, Button, Badge, COLORS } from '@connectedlab/lanexo-liquid-design-react';
import { customTheme } from '@lanexo2/ui-core';
import { CustomIcon } from '../Icons/CustomIcon';
import appConfirm1 from '../images/appConfirm1.png';
import appConfirm2 from '../images/appConfirm2.png';
import { useTranslation, UseTranslationResponse } from 'react-i18next';

interface IProps {
    cancel: () => void;
}

const imgStyle: any = css`
    text-align: center;
    width: 100%;

    > img {
        max-width: 100%;
        min-height: 200px;
        max-height: 30vh;
    }
`;

const textRowStyle: any = css`
    flex-flow: row;
    margin-top: 1em;

    div:first-of-type {
        margin-right: 0.3rem;
    }
`;

export const StageConfirm: React.FC<IProps> = ({ cancel }: IProps) => {
    const { t }: UseTranslationResponse<'translation'> = useTranslation();

    return (
        <>
            <Headline type="H2">{t('common.UseTheApp')}</Headline>

            <Row
                gutter={16}
                css={css`
                    margin: 3em 0;
                `}
            >
                <Col xs={24} md={12}>
                    <div css={imgStyle}>
                        <img src={appConfirm1} alt={''} />
                    </div>
                    <Row css={textRowStyle}>
                        <Col>
                            <Bubble label={1} />
                        </Col>
                        <Col>{t('common.label1')}</Col>
                    </Row>
                </Col>
                <Col xs={24} md={12}>
                    <div css={imgStyle}>
                        <img src={appConfirm2} alt={''} />
                    </div>
                    <Row css={textRowStyle}>
                        <Col>
                            <Bubble label={2} />
                        </Col>
                        <Col>{t('common.label2')}</Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="end" align="middle">
                <Col>
                    <Button
                        type="button"
                        onClick={cancel}
                        appearance="ghost"
                        size="big"
                        css={css`
                            div {
                                color: ${customTheme.colors.secondary.base} !important;
                            }
                        `}
                    >
                        {t('common.Back')}
                    </Button>
                </Col>

                <Col>
                    <Badge
                        css={css`
                            padding-top: 0.5em;
                            padding-bottom: 0.5em;
                            color: ${COLORS.WHITE};
                        `}
                    >
                        <CustomIcon name="pending" color={COLORS.WHITE} /> {t('common.ConfirmationPending')}
                    </Badge>
                </Col>
            </Row>
        </>
    );
};
