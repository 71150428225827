/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { COLORS } from '@connectedlab/lanexo-liquid-design-react';

const url = 'https://lanexo.com/';
export const DefaultFooter: any = (): any => {
    const { t, i18n }: UseTranslationResponse<'translation'> = useTranslation();
    const pathnames: any = {
        imprint: url + i18n.language + '/' + 'imprint',
        privacy: url + i18n.language + '/' + 'privacy',
        termsOfUse: url + i18n.language + '/' + 'tos',
    };
    return (
        <div
            css={css`
                padding-bottom: 10px;
                padding-top: 10px;
                text-align: right;
                a {
                    color: ${COLORS.WHITE};
                    font-size: 16px;
                    margin-right: 25px;
                }
            `}
        >
            <a href={pathnames.imprint} target="_blank" rel="noopener noreferrer">
                {t('common.linkImprint')}
            </a>
            <a href={pathnames.privacy} target="_blank" rel="noopener noreferrer">
                {t('common.linkPrivacyPolicy')}
            </a>
            <a href={pathnames.termsOfUse} target="_blank" rel="noopener noreferrer">
                {t('common.linkTermsOfUse')}
            </a>
        </div>
    );
};
