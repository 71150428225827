/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Col, Row } from 'antd';
import { useState } from 'react';
import { TextField, Headline, Button } from '@connectedlab/lanexo-liquid-design-react';
import { defaultGutter, customTheme } from '@lanexo2/ui-core';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import accessKeyCard from '../images/accessKeyCard.svg';

export interface IStageKeyValues {
    key: string;
}

interface IProps {
    handleFormSubmit: (
        values: IStageKeyValues,
        actions: {
            setSubmitting(isSubmitting: boolean): void;
        },
    ) => void;
    cancel: () => void;
}

const monospaceFontStyle: any = css`
    font-family: monospace;
`;
const cols: any = [...Array(16)];

export const StageKey: React.FC<IProps> = ({ cancel, handleFormSubmit }: IProps) => {
    const { t }: UseTranslationResponse<'translation'> = useTranslation();

    const [key, setKey]: [any, (any) => void] = useState('');
    const [keyArray, setKeyArray]: [any, (any) => void] = useState([] as string[]);
    const [columnMode, setColumnMode]: [any, (any) => void] = useState(false);
    const [isSubmmiting, setSubmitting]: [any, (any) => void] = useState(false);

    const toggleColumnMode: any = () => {
        if (!columnMode) {
            const newArray = [] as string[];
            for (let i = 0; i < key.length; i += 4) {
                newArray.push(key.substring(i, i + 4));
            }
            setKeyArray(newArray);
        }
        setColumnMode(!columnMode);
    };

    const handleInputArrayChange: any = (index: number, value: string) => {
        const newArray: any = [...keyArray];
        newArray[index] = value;
        setKeyArray(newArray);
        setKey(newArray.join(''));
    };

    const handleSubmit: any = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitting(true);
        handleFormSubmit({ key }, { setSubmitting });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Headline type="H2">{t('key.headline')}</Headline>

            <div
                css={css`
                    text-align: center;
                `}
            >
                <img
                    src={accessKeyCard}
                    css={css`
                        text-align: center;
                        margin: 2em auto;
                        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
                    `}
                    alt={''}
                />
            </div>

            {columnMode ? (
                <Row css={monospaceFontStyle} gutter={defaultGutter}>
                    {cols.map((_, index) => (
                        <Col xs={8} sm={6} md={4} key={index}>
                            <TextField
                                value={keyArray[index]}
                                onChange={(value) => handleInputArrayChange(index, value)}
                                grey={true}
                                size={4}
                                data-testid="col-mode"
                                maxLength={4}
                                placeholder="----"
                                disabled={isSubmmiting}
                                autoFocus={index === 0}
                                data-private="lipsum"
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <TextField
                    value={key}
                    onChange={setKey}
                    label={t('key.label')}
                    name="key"
                    multiline={true}
                    data-testid="row-mode"
                    style={{ display: 'block' }}
                    css={monospaceFontStyle}
                    disabled={isSubmmiting}
                    maxLength={64}
                    autoFocus={true}
                    data-private="lipsum"
                />
            )}

            <Row justify="end" align="middle">
                <Col>
                    <Button
                        type="button"
                        onClick={toggleColumnMode}
                        appearance="ghost"
                        size="big"
                        disabled={isSubmmiting}
                        css={css`
                            div {
                                color: ${customTheme.colors.secondary.base} !important;
                            }
                        `}
                    >
                        {columnMode ? t('key.textMode') : t('key.columnMode')}
                    </Button>
                </Col>

                <Col>
                    <Button
                        type="button"
                        onClick={cancel}
                        appearance="ghost"
                        size="big"
                        disabled={isSubmmiting}
                        css={css`
                            div {
                                color: ${customTheme.colors.secondary.base} !important;
                            }
                        `}
                    >
                        {t('common.Back')}
                    </Button>
                </Col>

                <Col>
                    <Button
                        type="submit"
                        data-testid="stagekey-submit"
                        appearance="primary"
                        size="big"
                        disabled={isSubmmiting || key.length !== 64}
                    >
                        {t('common.Login')}
                    </Button>
                </Col>
            </Row>
        </form>
    );
};
