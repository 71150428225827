import { useEffect } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';

const useTranslateFormErrors: any = (errors, touched, setFieldTouched) => {
    const { i18n }: UseTranslationResponse<'translation'> = useTranslation();
    useEffect(() => {
        i18n.on('languageChanged', () => {
            Object.keys(errors).forEach((fieldName) => {
                setFieldTouched(fieldName, true, true);
            });
        });
        return () => {
            i18n.off('languageChanged', () => {
                /*eslint no-empty-function: "error"*/
            });
        };
    }, [errors]);
};

const WithTranslateFormErrors: any = ({ errors, touched, setFieldTouched, children }: any) => {
    useTranslateFormErrors(errors, touched, setFieldTouched);
    return <>{children}</>;
};

export default WithTranslateFormErrors;
