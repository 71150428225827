/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { Dropdown, COLORS } from '@connectedlab/lanexo-liquid-design-react';
import { useEffect } from 'react';
import { setCookie } from './../cookie';

export const LanguageSelect: React.FC = () => {
    const { t, i18n }: UseTranslationResponse<'translation'> = useTranslation();
    // get language code w/o country code
    const languageCode: any = i18n.language;
    useEffect(() => {
        localStorage.setItem('i18nextLng', languageCode);
        setCookie('i18nextLng', languageCode, 'Fri, 31 Dec 9999 23:59:59 GMT');
    }, [languageCode]);

    const LabelWrapper: any = styled.label`
        display: block;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 12px;
        color: ${COLORS.RICH_BLACK_LIGHTEST};
    `;
    return (
        <div>
            <LabelWrapper className="LD__TextField__label">{t('common.language')}</LabelWrapper>
            <div
                css={css`
                    > div:first-of-type {
                        width: 100%;
                    }

                    .LD__Dropdown {
                        width: 100%;
                        max-width: none;
                    }
                `}
            >
                <div
                    css={css`
                        > div:last-of-type {
                            min-width: 10rem;
                        }
                    `}
                >
                    <div
                        css={css`
                            display: inline-block;

                            div:first-of-type {
                                min-width: 80px;
                                color: ${COLORS.RICH_PURPLE};
                                font-weight: 900;
                            }

                            svg {
                                fill: ${COLORS.RICH_PURPLE_LIGHT};
                            }
                        `}
                    >
                        <Dropdown
                            options={[
                                { name: t('common.lang.de'), id: 'de' },
                                { name: t('common.lang.en'), id: 'en' },
                            ]}
                            value={languageCode}
                            onSubmit={({ id: lng }: { id: string }) => i18n.changeLanguage(lng)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
