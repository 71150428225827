import React from 'react';
import { Route, Switch } from 'react-router-dom';
import 'antd/dist/antd.css';
import { ResetPassword } from './pages/ResetPassword';
import { SetPassword } from './pages/SetPassword';
import { UpdatePassword } from './pages/UpdatePassword';
import Loginpage from './pages/login';
import { Layout } from './components/Layout';
import ForgotPassword from './components/ForgotPassword';

export const LoginModule: any = () => {
    return (
        <Layout>
            <Switch>
                <Route
                    exact={true}
                    path={`/password-reset/:passwordResetToken/`}
                    render={(props) => <ResetPassword {...props} />}
                />
                <Route
                    exact={true}
                    path={`/confirm-registration/:passwordSetToken/`}
                    render={(props) => <SetPassword {...props} />}
                />
                <Route exact={true} path={`/update-password`} render={(props) => <UpdatePassword {...props} />} />
                <Route exact={true} path={`/forgot-password`} render={(props) => <ForgotPassword {...props} />} />
                <Route path={`/`} render={() => <Loginpage />} />
            </Switch>
        </Layout>
    );
};
