/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ILoginData } from '../components/Form';
import StageStart from '../components/StageStart';
import { StageConfirm } from '../components/StageConfirm';
import { IStageKeyValues, StageKey } from '../components/StageKey';
import { Row } from 'antd';
import { Loading } from '@lanexo2/ui-core';
import { FormikHelpers } from 'formik';
import { Notifications } from '@connectedlab/lanexo-liquid-design-react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import '../languages/i18n';
import { getDeviceId } from '../utils/deviceId';
import { getTimeZone } from '../utils/timezone';
import { css } from '@emotion/react';

enum STAGES {
    start = 'start',
    app = 'app',
    key = 'key',
}
let tokenInterval: any;
const Credentials = 'Credentials';
const Hybrid = 'Hybrid';

const Loginpage: any = () => {
    const [stage, setStageState]: [any, (any) => void] = useState<STAGES>(STAGES.start);
    const [loginResponse, setloginResponse]: [any, (any) => void] = useState<any>();
    const [authOption, setAuthOption] = useState<string>();
    const { t }: UseTranslationResponse<'translation'> = useTranslation();
    let notificationsRef: any;
    const history = useHistory();
    useEffect(() => {
        getTimeZone();
    }, []);

    const fetchUseKeyStatus: any = async () => {
        let useKeyStatus = false;
        await fetch('/config', {
            method: 'GET',
        })
            .then(async function (response) {
                if (!response.ok) {
                    throw await response.json();
                }
                return response.json();
            })
            .then((data) => {
                const { useKeyAvailable } = data;
                if (useKeyAvailable) {
                    useKeyStatus = true;
                }
                return useKeyStatus;
            });
    };

    const knownErrorCodes = [
        'DEVICE_APPROVAL_REQUIRED',
        'INVALID',
        'INVALID_PASSWORD',
        'ACCOUNT_LOCKED',
        'ACCOUNT_INACTIVE',
        'INVALID_CREDENTIALS',
        'MISSING_APPROVAL',
        'LOGIN_NOT_ALLOWED',
    ];
    const callTokenAPI = (jsonresp) => {
        tokenInterval = setInterval(() => tokenreader(jsonresp, false), 2000);
    };
    const clearTokenAPICall = () => {
        clearInterval(tokenInterval);
    };

    const tokenreader: any = (jsonresp, reCall = true) => {
        fetch('/api/authorization/token', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonresp),
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error('HTTP status ' + response.status);
                }
                return response.json();
            })
            .then((response) => {
                if (!response.token) {
                    if (reCall) {
                        callTokenAPI(jsonresp);
                    }
                }
                if (response.token) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                if (error?.message?.split(' ')?.[2] == 401) {
                    if (reCall) {
                        callTokenAPI(jsonresp);
                    }
                } else {
                    console.log(error);
                }
            });
    };
    const approveLogin: any = (jsonresp, setSubmitting) => {
        fetch('/api/authorization/sign-session-key', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonresp),
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error('HTTP status ' + response.status);
                }
                return response;
            })
            .then(async () => {
                tokenreader(loginResponse);
                setSubmitting(false);
            })
            .catch(() => {
                notificationsRef.addNotification({
                    text: t('errors.INVALID_ACCESS_CARD'),
                    isInfo: true,
                });
                setSubmitting(false);
            });
    };

    const handleFormSubmit: any = (values: ILoginData, actions: FormikHelpers<ILoginData>) => {
        const loginvalues: any = {
            emailAddress: values.email,
            password: values.password,
            clientId: 'web',
            deviceId: getDeviceId(),
        };

        fetch('/api/authorization/add-session-key', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginvalues),
        })
            .then(async function (response) {
                if (!response.ok) {
                    throw await response.json();
                }
                return response;
            })
            .then((response) => response.json())
            .then(async (jsonresp) => {
                if (knownErrorCodes.includes(jsonresp.statusCode)) {
                    if (jsonresp.statusCode === 'LOGIN_NOT_ALLOWED') {
                        history.push({
                            pathname: '/update-password',
                            emailAddress: values.email,
                        });
                    }
                    throw jsonresp;
                }
                if (jsonresp.statusCode === 'MFA_REQUIRED') {
                    const useKeyStatus = fetchUseKeyStatus();
                    if (useKeyStatus && window.location.href.includes('#usekey')) {
                        setStageState(STAGES.key);
                    } else {
                        //TO Do Refactor
                        (jsonresp.accessCode.authOption === Credentials || jsonresp.accessCode.authOption === Hybrid) &&
                            (await approveLogin(jsonresp.accessCode));
                        setStageState(STAGES.app);
                        setAuthOption(jsonresp.accessCode.authOption);
                        tokenreader(jsonresp.accessCode);
                    }
                    setloginResponse(jsonresp.accessCode);
                } else if (jsonresp.statusCode === 'SUCCESS') {
                    tokenreader(jsonresp.accessCode);
                }
            })
            .catch((error) => {
                if (error.statusCode === 'ACCOUNT_LOCKED') {
                    const remaingTime: any = error.blockSecond !== 0 ? Math.ceil((error.blockSecond - 30) / 60) : false;
                    if (remaingTime || error.blockSecond > 1) {
                        if (error.blockSecond > 90) {
                            notificationsRef.addNotification({
                                text: t('errors.ACCOUNT_LOGIN_BLOCKED', { time: remaingTime }),
                                isInfo: true,
                            });
                        } else if (error.blockSecond > 45 && error.blockSecond < 90) {
                            notificationsRef.addNotification({
                                text: t('errors.ACCOUNT_LOGIN_BLOCKED_MINUTES'),
                                isInfo: true,
                            });
                        } else {
                            notificationsRef.addNotification({
                                text: t('errors.ACCOUNT_LOGIN_BLOCKED_SECONDS'),
                                isInfo: true,
                            });
                        }
                    } else {
                        notificationsRef.addNotification({
                            text: t('errors.ACCOUNT_LOGIN_BLOCKED_SOME_TIME', {
                                time: t('common.relativeTimeInFuture'),
                            }),
                            isInfo: true,
                        });
                    }
                } else if (error.statusCode === 'INVALID_CREDENTIALS') {
                    notificationsRef.addNotification({ text: t('errors.INVALID_PASSWORD'), isInfo: true });
                } else if (error.errorCode === 'INVALID_PARTIAL_USER_LOGIN') {
                    notificationsRef.addNotification({ text: t('errors.INVALID_PARTIAL_USER_LOGIN'), isInfo: true });
                } else if (error.statusCode === 'DEVICE_APPROVAL_REQUIRED') {
                    notificationsRef.addNotification({ text: t('errors.UNKNOWN_DEVICE'), isInfo: true });
                } else if (error.message === 'Customer is inActive') {
                    notificationsRef.addNotification({ text: t('errors.CUSTOMER_INACTIVE'), isInfo: true });
                } else if (error.message === 'Customer can not login before start date') {
                    notificationsRef.addNotification({ text: t('errors.CUSTOMER_START_DATE'), isInfo: true });
                } else if (error.message === 'Customer can not login after end date') {
                    notificationsRef.addNotification({ text: t('errors.CUSTOMER_END_DATE'), isInfo: true });
                } else if (error.statusCode === 'ACCOUNT_INACTIVE') {
                    notificationsRef.addNotification({ text: t('errors.MISSING_APPROVAL'), isInfo: true });
                } else if (error.message === 'User Registration is pending for Approval') {
                    notificationsRef.addNotification({ text: t('errors.APPROVAL_REQUIRED'), isInfo: true });
                } else if (error.message === 'Login currently only possible for Administrators') {
                    notificationsRef.addNotification({ text: t('errors.UserLicenceChanges'), isInfo: true });
                } else {
                    notificationsRef?.addNotification({ text: t('errors.INVALID'), isInfo: true });
                }
            });
        actions.setSubmitting(false);
    };

    const handleKeySubmit: any = async (
        /* eslint-disable @typescript-eslint/no-unused-vars */
        values: IStageKeyValues,
        { setSubmitting }: { setSubmitting: (value: boolean) => void },
    ) => {
        const loginDataSignSession: any = Object.assign({}, loginResponse);
        loginDataSignSession['secret'] = values.key;
        approveLogin(loginDataSignSession, setSubmitting);
    };
    const backToStart: any = () => {
        clearTokenAPICall();
        setStageState(STAGES.start);
    };
    return (
        <>
            {stage === STAGES.start && (
                <div>
                    <StageStart handleFormSubmit={handleFormSubmit} />
                </div>
            )}
            {!(authOption === Credentials || authOption === Hybrid) && stage === STAGES.app && (
                <div>
                    <StageConfirm cancel={backToStart} />
                </div>
            )}
            {stage === STAGES.key && <StageKey handleFormSubmit={handleKeySubmit} cancel={backToStart} />}
            {(authOption === Credentials || authOption === Hybrid) && stage === STAGES.app && (
                <Row
                    css={css`
                        display: flex;
                    `}
                    justify="center"
                >
                    <Loading
                        css={css`
                            margin-top: 15px !important;
                        `}
                        size="small"
                    />
                    <p className="loaderHeader">{t('common.loaderHeader')}</p>
                </Row>
            )}
            <Notifications
                ref={(v) => {
                    notificationsRef = v;
                }}
            />
        </>
    );
};

export default Loginpage;
