/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Formik, FormikHelpers } from 'formik';
import { Row, Col } from 'antd';
import { Headline, TextField, Button, COLORS } from '@connectedlab/lanexo-liquid-design-react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { customTheme, defaultSmBottomMargin } from '@lanexo2/ui-core';
import { LanguageSelect } from './LanguageSelect';
import * as yup from 'yup';
import WithTranslateFormErrors from './FormErrors';
import i18n from 'i18next';
import '../css/Form.css';
import { useHistory } from 'react-router-dom';

const customButtonLabelStyle: any = css`
    div {
        color: ${customTheme.colors.secondary.base} !important;
    }
`;

const customButtonIconStyle: any = css`
    svg {
        fill: ${customTheme.colors.secondary.base} !important;
    }
`;
const customButtonDisabledLabelStyle: any = css`
    background-color: ${COLORS.VIBRANT_YELLOW_LIGHTEST} !important;
`;

export interface ILoginData {
    email: string;
    password: string;
    skipSession: boolean;
}
interface IProps {
    handleFormSubmit: (values: ILoginData, actions: FormikHelpers<ILoginData>) => void;
}
const LoginForm: any = ({ handleFormSubmit }: IProps): any => {
    const { t }: UseTranslationResponse<'translation'> = useTranslation();
    const validationSchema: any = yup.object().shape({
        email: yup.string().email('errors.email').required('errors.required'),
        password: yup.string().required('errors.required').min(6, 'errors.min').max(70, 'errors.max'),
    });
    const history = useHistory();

    return (
        <>
            <Formik
                initialValues={{ email: '', password: '', skipSession: false }}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            >
                {({
                    values,
                    handleBlur,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    errors,
                    touched,
                    isValid,
                    dirty,
                }) => (
                    <WithTranslateFormErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                        <form onSubmit={handleSubmit}>
                            <Headline type="H2" css={defaultSmBottomMargin}>
                                {t('common.welcome')}
                            </Headline>

                            <TextField
                                grey={true}
                                label={t('common.email')}
                                data-testid="loginEmail"
                                name="email"
                                value={values.email}
                                onChange={(input) => setFieldValue('email', input)}
                                error={touched.email && errors.email && t(`${errors.email}`)}
                                onBlur={handleBlur}
                                style={{ display: 'block' }}
                            />

                            <TextField
                                grey={true}
                                label={t('common.password')}
                                data-testid="loginPassword"
                                type="password"
                                name="password"
                                value={values.password}
                                onChange={(input) => setFieldValue('password', input)}
                                error={touched.password && errors.password && t(`${errors.password}`)}
                                onBlur={handleBlur}
                                style={{ display: 'block' }}
                            />
                            <Row justify={'space-between'} align={'bottom'}>
                                <Col>{/* {t('common.rememberMe')} */}</Col>
                                <Col>
                                    <div className="forgotPassword" onClick={() => history.push('/forgot-password')}>
                                        {t('common.forgotPassword')}
                                    </div>
                                </Col>
                            </Row>

                            <Row justify={'space-between'} align={'bottom'}>
                                <Col>
                                    <LanguageSelect />
                                </Col>
                                <Col>
                                    <div
                                        css={css`
                                            text-align: right;
                                        `}
                                    >
                                        <Button
                                            type="submit"
                                            data-testid="loginSubmit"
                                            appearance="primary"
                                            size="big"
                                            disabled={isSubmitting || !(isValid && dirty)}
                                            className={(isValid || dirty) && 'disabledBtn'}
                                            css={[
                                                customButtonIconStyle,
                                                ...(!(isSubmitting || !(isValid && dirty))
                                                    ? [customButtonLabelStyle]
                                                    : [customButtonDisabledLabelStyle]),
                                            ]}
                                        >
                                            <div className="sAmpM"> {i18n.t('common.Login')}</div>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </WithTranslateFormErrors>
                )}
            </Formik>
        </>
    );
};

export default LoginForm;
