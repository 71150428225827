const containsChar = (value) => value.match(/[A-Za-z]/);
const containsDigit = (value) => value.match(/[0-9]/);
const containsSpecialCharacters = (value) => value.match(/[^A-Za-z0-9]/);

const validatePassword = (value, passwordPolicy) => {
  if (
    passwordPolicy?.mustHaveCharacters &&
    passwordPolicy?.mustHaveNumbers &&
    passwordPolicy?.mustHaveSpecialCharacters
  ) {
    return containsChar(value) && containsDigit(value) && containsSpecialCharacters(value);
  } else if (passwordPolicy?.mustHaveCharacters && passwordPolicy?.mustHaveNumbers) {
    return containsChar(value) && containsDigit(value);
  } else if (passwordPolicy?.mustHaveCharacters && passwordPolicy?.mustHaveSpecialCharacters) {
    return containsChar(value) && containsSpecialCharacters(value);
  } else if (passwordPolicy?.mustHaveNumbers && passwordPolicy?.mustHaveSpecialCharacters) {
    return containsDigit(value) && containsSpecialCharacters(value);
  } else if (passwordPolicy?.mustHaveCharacters) {
    return containsChar(value);
  } else if (passwordPolicy?.mustHaveNumbers) {
    return containsDigit(value);
  } else if (passwordPolicy?.mustHaveSpecialCharacters) {
    return containsSpecialCharacters(value);
  } else if (passwordPolicy?.minimumLength) {
    return value.length >= passwordPolicy?.minimumLength;
  }
};

const isValidValue = (value, values) => {
  const newPasswordValid = values.passwordRepeat === values.newPassword && values.newPassword;
  return value === newPasswordValid;
};

export const validatePasswordAndConfPassword = (
  value,
  values,
  passwordPolicy,
  setStateErrorsForValid,
  setStateErrorsForInValid,
) => {
  const isValidPassword = validatePassword(value, passwordPolicy) && isValidValue(value, values);
  isValidPassword ? setStateErrorsForValid() : setStateErrorsForInValid();
};
