import React, { useEffect, useState } from 'react';
import { Headline, Notifications } from '@connectedlab/lanexo-liquid-design-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { Trans } from 'react-i18next';
import { defaultSmBottomMargin } from '@lanexo2/ui-core';
import { SetPasswordForm } from '../components/SetPasswordForm';
import '../languages/i18n';
import i18n from 'i18next';

interface IRouteParams {
    passwordResetToken: string;
}

export const SetPassword: React.FC<RouteComponentProps<IRouteParams>> = ({
    match,
}: RouteComponentProps<IRouteParams>) => {
    const [wasSubmitted, setWasSubmitted]: [any, (any) => void] = useState(false);
    const passwordResetToken: any = match?.params?.passwordSetToken;
    const [values, setValue] = useState({
        newPassword: '',
        passwordRepeat: '',
    });
    const [passwordPolicy, setPasswordPolicy] = useState<any>();
    const { t }: UseTranslationResponse<'translation'> = useTranslation();

    let notificationsRef: any;

    useEffect(() => {
        const url: any = window.location;
        const urls = new URL(url);
        const tenantId = urls.searchParams.get('tenantId');
        fetchPasswordPolicy(tenantId);
    }, [passwordResetToken]);

    const fetchPasswordPolicy: any = async (tenantId) => {
        const url = `/api/authorization/fetch-auth-policy?tenantId=${tenantId}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(async(response) => {
                if (!response.ok) {
                    const message: any = await response.json().then((data) => data?.message || 'Error');
                    throw message;
                }
                return response.json();
            })
            .then((response) => {
                setPasswordPolicy(response);
            })
            .catch(() => {
                setPasswordPolicy({
                    passwordId: 4,
                    authOption: "MinimumValues",
                    minimumLength: 6,
                    mustHaveCharacters: false,
                    mustHaveNumbers: false,
                    mustHaveSpecialCharacters: false
                })
            });
    };

    const handleFormSubmit: any = async () => {
        if (
            !passwordResetToken ||
            !values.newPassword ||
            !values.passwordRepeat ||
            values.newPassword !== values.passwordRepeat
        ) {
            notificationsRef.addNotification({
                text: i18n.t('common.PasswordResetToken'),
                isInfo: true,
            });
            throw new Error('something wrong');
        }

        const url = '/api/authorization/mailConfirmation';

        const SetPassword: any = {
            password: values.newPassword,
            notificationToken: passwordResetToken,
        };
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(SetPassword),
        })
            .then(async function (response) {
                if (!response.ok) {
                    return response.json().then((json) => {
                        throw json;
                    });
                }
                return response;
            })
            .then(() => {
                setWasSubmitted(true);
            })
            .catch((error) => {
                if (error == 'encryption error') {
                    notificationsRef.addNotification({
                        text: t('errors.INVALID_TOKEN'),
                        isInfo: true,
                    });
                } else if (error?.status === 'BAD_REQUEST') {
                    notificationsRef.addNotification({
                        text: error?.message,
                        isInfo: true,
                    });
                } else {
                    notificationsRef.addNotification({
                        text: t('errors.SOMETHING_WRONG'),
                        isInfo: true,
                    });
                }
            });
    };

    return (
        <div className="wasSubmitted">
            <Headline type="H2" css={defaultSmBottomMargin}>
                {t('passwordSet.headline')}
            </Headline>
            {wasSubmitted ? (
                <Trans i18nKey={'passwordSet.wasSubmitted'} components={[<Link key={0} to={'/'} />]} />
            ) : (
                <SetPasswordForm
                    handleFormSubmit={handleFormSubmit}
                    setValues={setValue}
                    passwordPolicy={passwordPolicy}
                />
            )}
            <Notifications
                ref={(v) => {
                    notificationsRef = v;
                }}
            />
        </div>
    );
};
