import LoginForm, { ILoginData } from './Form';
import { FormikHelpers } from 'formik';

interface IProps {
    handleFormSubmit: (values: ILoginData, actions: FormikHelpers<ILoginData>) => void;
}

const StageStart: any = ({ handleFormSubmit }: IProps) => {
    return <LoginForm handleFormSubmit={handleFormSubmit} />;
};

export default StageStart;
