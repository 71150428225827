import React from 'react';
import { LoginModule } from './LoginModule';
import { BrowserRouter, RouteComponentProps, useHistory } from 'react-router-dom';
const app: any = () => {
    const history: RouteComponentProps = useHistory();
    return (
        <BrowserRouter history={history}>
            <LoginModule />
        </BrowserRouter>
    );
};

export default app;
